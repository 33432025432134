import { Injectable } from '@angular/core';
import { Resource, ResourceFactory, ResourceUri, getUrl } from '@ngxp/rest';
import { Observable } from 'rxjs';
import { CreateResourceData, LinkRelationName, SaveResourceData } from './resource.model';
import { ListResource } from './resource.util';

@Injectable({ providedIn: 'root' })
export class ResourceRepository {
  static SEARCH_PARAM: string = 'searchBy';

  constructor(private resourceFactory: ResourceFactory) {}

  public getListResource(resource: Resource, linkRel: string): Observable<ListResource> {
    const uriWithoutParameter: string = this.getUrlWithoutParameter(getUrl(resource, linkRel));
    return this.resourceFactory.fromId(uriWithoutParameter).get();
  }

  getUrlWithoutParameter(uri: ResourceUri): string {
    if (uri.includes('{')) {
      return uri.substring(0, uri.indexOf('{'));
    }
    return uri;
  }

  public createResource(createResourceData: CreateResourceData<Resource>): Observable<Resource> {
    return this.resourceFactory
      .from(createResourceData.resource)
      .post(createResourceData.linkRel, createResourceData.toCreate);
  }

  public getResource<T>(uri: ResourceUri): Observable<T> {
    return this.resourceFactory.fromId(uri).get();
  }

  public save(saveResourceData: SaveResourceData<Resource>): Observable<Resource> {
    return this.resourceFactory
      .from(saveResourceData.resource)
      .put(saveResourceData.linkRel, saveResourceData.toSave);
  }

  public delete(resource: Resource, linkRel: LinkRelationName): Observable<Resource> {
    return this.resourceFactory.from(resource).delete(linkRel);
  }

  public search<T>(resource: Resource, linkRel: LinkRelationName, searchBy: string): Observable<T> {
    return this.resourceFactory
      .fromId(this.buildSearchUri(new URL(getUrl(resource, linkRel)), searchBy))
      .get();
  }

  buildSearchUri(url: URL, searchBy: string): ResourceUri {
    url.searchParams.set(ResourceRepository.SEARCH_PARAM, searchBy);
    return url.href;
  }
}

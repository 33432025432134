import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ods-popup-list-item',
  standalone: true,
  imports: [CommonModule],
  template: `<button
    class="flex min-h-12 w-full items-center gap-4 border-2 border-transparent bg-whitetext px-4 py-3 text-start outline-none hover:border-primary focus-visible:border-focus"
    role="listitem"
    (click)="itemClicked.emit()"
  >
    <ng-content select="[icon]" />
    <p class="text-text">{{ caption }}</p>
  </button>`,
})
export class PopupListItemComponent {
  @Input({ required: true }) caption!: string;

  @Output() itemClicked: EventEmitter<MouseEvent> = new EventEmitter();
}
